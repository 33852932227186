import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { memo } from 'react';
import { useSnapshot } from 'valtio';
import { AppLink2 } from '~/components/AppLink2';
import { fill_horizontal_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { useExtremeVolumeResource, } from '~/modules/screener/containers/useStockScreenerResource';
import { useDatePick } from '~/modules/screener/useDatePick';
import SymbolPlatformBase from '../../modules/symbolPlatform/base/SymbolPlatformBase';
import { goodpsy_store } from './goodpsy_store';
const watchListGroup = 'goodpsy_group_1';
export const Goodpsy_SidePane2 = memo(function Goodpsy_SidePane2() {
    //選股
    const date = useDatePick();
    // const dailyRankData = usePercentRangeResource({
    //   days: 5,
    //   status: 'new_high',
    //   volume_gte: 5000,
    //   volume_lte: 0,
    // })
    const extremeVolumeData = useExtremeVolumeResource({
        date: date,
        days: 5,
        volume_gte: 10000,
        volume_lte: 0,
        threshold: 1.25,
    });
    //const dailyRankSymbol = dailyRankData.data?.map(datum => datum.symbol)
    const extremeVolumeSymbol = extremeVolumeData.data
        ?.filter(a => a.volume / a.volume_ma < 1.25 * 2)
        ?.map(datum => datum.symbol);
    const allSymbol = extremeVolumeSymbol;
    return (<styleds.container>
      <styleds.pageContent>
        <PageSwitch url='/goodpsy' keys='default'>
          技術分析
        </PageSwitch>
        <PageSwitch url='/goodpsy/option' keys='option'>
          選擇權
        </PageSwitch>
      </styleds.pageContent>

      <styleds.componentCard height={'calc(100% - 48px)'}>
        <SymbolPlatformBase.Display symbol={allSymbol ?? []} watchListGroup={watchListGroup} listTypeSwitch={false}/>
      </styleds.componentCard>
    </styleds.container>);
});
const PageSwitch = memo(function PageSwitch(props) {
    const state = useSnapshot(goodpsy_store).pageState;
    const currentUrl = window.location.href;
    const selectedPage = state === props.keys || currentUrl.includes(props.keys);
    return (<styleds.switchButton selected={selectedPage} onClick={() => (goodpsy_store.pageState = props.keys)}>
        <AppLink2 href={props.url} css={css `
            ${fill_horizontal_all_center};
            color: ${selectedPage ? '#252525' : '#aaaaaa'};
          `}>
          {props.children}
          {selectedPage}
        </AppLink2>
      </styleds.switchButton>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    padding: 4px;
    gap: 4px;
  `,
    componentCard: styled.div `
    width: 100%;
    height: ${props => props.height};
    padding: 4px;
    border-radius: 6px;
  `,
    pageContent: styled.div `
    ${fill_horizontal_all_center};
    height: 40px;
    gap: 4px;
  `,
    switchButton: styled.div `
    ${fill_horizontal_all_center};
    width: 100%;
    height: 32px;
    background-color: #fafafa;
    border-radius: 4px;
    cursor: pointer;
    color: #252525;
    border: 1px solid ${props => (props.selected ? '#222222' : '#cacaca')};
    &:hover {
      background-color: #f0f0f0;
    }
    user-select: none;
  `,
};
