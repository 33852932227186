import { css } from '@emotion/react';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { createIPadCss } from '~/css/createIPadCss';
import { createMobileCss2 } from '~/css/createMobileCss';
import { meCheckHandlerCreateByProductNameWithExpiredAt } from '~/modules/SDK/me/meCheckHandlerCreateByProductName';
import { store } from '~/pages/heineken_template/_private/store';
import { Preset_LoginPageOfCarousel } from '../heineken_template/_preset/preset_LoginPageOfCarousel';
import { goodpsy_initStrategies } from './goodpsy_initStrategies';
import { goodpsy_initStyling } from './goodpsy_initStyling';
import { Goodpsy_SidePane1 } from './goodpsy_SidePane1';
import { Goodpsy_SidePane2 } from './goodpsy_SidePane2';
import { Goodpsy_Topbar } from './goodpsy_Topbar';
import { Goodpsy_OptionQuote } from './option/goodpsy_optionQuote';
import { useSignalrStart2_0 } from '~/modules/SDK/Signalr/useSignalrStart2_0';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import Fr_WithDrawerCloseButton from '~/pages/heineken_template/_fr/fr_WithDrawerCloseButton';
export const goodpsy_init = {
    global(templateProps) {
        goodpsy_initStyling(templateProps);
        goodpsy_initStrategies();
        useThemeStore.setState({ theme: 'light' });
        store.charting.setThemeMode('light');
        templateProps.layout.Charting = undefined;
        templateProps.layout.Row1 = Goodpsy_Topbar;
    },
    indexPage(templateProps) {
        goodpsy_initStyling(templateProps);
        goodpsy_initStrategies();
        useThemeStore.setState({ theme: 'light' });
        store.charting.setThemeMode('light');
        templateProps.layout.login = (<Preset_LoginPageOfCarousel resources={[{ image: [true, '/goodpsy_init/loginView.jpeg'] }]}/>);
        templateProps.permissions.pageview = meCheckHandlerCreateByProductNameWithExpiredAt('web');
        store.charting.widgetOptions = {
            ...store.charting.widgetOptions,
            symbol: 'TX-1',
            interval: 'D',
            enableVolumeIndicator: false,
            disabledHeaderWidget: false,
            disabledLeftToolbar: false,
            disabledTimeframesToolbar: true,
            disabledHeaderChartType: true,
            disabledHeaderCompare: true,
            disabledGoToDate: true,
            disabledHeaderSaveload: true,
            overrides: store.charting.lightOverrides,
        };
        templateProps.layout.cssset = css `
      grid-template-rows: 48px 1fr;
      grid-template-columns: 336px 1fr 336px;
      place-content: flex-start;

      grid-template-areas:
        'Row1 Row1 Row1'
        'Drawer1 Chart Drawer2'
        'Drawer1 Chart Drawer2';

      ${createIPadCss(css `
        grid-template-rows: 48px 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Chart Chart Chart';
      `)}

      ${createMobileCss2(css `
        grid-template-rows: 48px 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Chart Chart Chart';
      `)}
    `;
        templateProps.layout.Row1 = Goodpsy_Topbar;
        templateProps.layout.Drawer1 = (<Fr_WithDrawerCloseButton.Display right Button={<BsChevronLeft css={css `
              #__html {
                position: relative;
                z-index: 100;
              }
              color: #ffffff;
              background-color: #42948844;
              border-radius: 5px;
              height: 64px;
              width: 28px;
              font-size: 24px;
            `}/>}>
        <Goodpsy_SidePane2 />
      </Fr_WithDrawerCloseButton.Display>);
        templateProps.layout.Drawer2 = (<Fr_WithDrawerCloseButton.Display left Button={<BsChevronRight css={css `
              #__html {
                position: relative;
                z-index: 100;
              }
              color: #ffffff;
              background-color: #42948844;
              border-radius: 5px;
              height: 64px;
              width: 28px;
              font-size: 24px;
            `}/>}>
        <Goodpsy_SidePane1 />
      </Fr_WithDrawerCloseButton.Display>);
        templateProps.hooks.add(useSignalrStart2_0);
    },
    ['option/index.page'](templateProps) {
        goodpsy_initStyling(templateProps);
        goodpsy_initStrategies();
        useThemeStore.setState({ theme: 'light' });
        store.charting.setThemeMode('light');
        templateProps.layout.Row1 = Goodpsy_Topbar;
        store.charting.widgetOptions = {
            ...store.charting.widgetOptions,
            symbol: 'TX-1',
            interval: '5',
            enableVolumeIndicator: true,
            disabledHeaderWidget: false,
            disabledLeftToolbar: true,
            disabledTimeframesToolbar: true,
            disabledHeaderChartType: true,
            disabledHeaderCompare: true,
            disabledGoToDate: true,
            disabledHeaderSaveload: true,
            disabledSymbolSearch: true,
            overrides: store.charting.lightOverrides,
        };
        templateProps.layout.cssset = css `
      grid-template-rows: 48px 1fr 1fr;
      grid-template-columns: 336px 1fr 1fr;
      place-content: flex-start;

      grid-template-areas:
        'Row1 Row1 Row1'
        'Drawer1 Chart Chart'
        'Drawer1 Col2 Col2';

      ${createIPadCss(css `
        grid-template-rows: 48px 1fr 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Chart Chart Chart'
          'Col2 Col2 Col2';
      `)}

      ${createMobileCss2(css `
        grid-template-rows: 48px 1fr 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Chart Chart Chart'
          'Col2 Col2 Col2';
      `)}
    `;
        templateProps.layout.Row1 = Goodpsy_Topbar;
        templateProps.layout.Drawer1 = (<Fr_WithDrawerCloseButton.Display right Button={<BsChevronLeft css={css `
              #__html {
                position: relative;
                z-index: 100;
              }
              color: #ffffff;
              background-color: #42948844;
              border-radius: 5px;
              height: 64px;
              width: 28px;
              font-size: 24px;
            `}/>}>
        <Goodpsy_SidePane2 />
      </Fr_WithDrawerCloseButton.Display>);
        templateProps.layout.Col2 = Goodpsy_OptionQuote;
        templateProps.hooks.add(useSignalrStart2_0);
    },
};
