import { store } from '~/pages/heineken_template/_private/store';
import { goodpsy_value } from '~/trades/indicators/goodpsy/goodpsy_value';
import { goodpsy_circle } from '~/trades/indicators/goodpsy/goodpsy_circle';
import { goodpsy_gwa } from '~/trades/indicators/goodpsy/goodpsy_gwa';
export const goodpsy_indicators = [
    //goodpsy_bband,
    //goodpsy_wma,
    //goodpsy_stochastic,
    // goodpsy_kd77,
    //goodpsy_macd77,
    // --------------- 本揚哥提供的指標 ---------------
    goodpsy_gwa,
    goodpsy_circle,
    goodpsy_value,
    //goodpsy_score,
];
export const goodpsy_strategiesGroup = {
    main: [
        {
            displayName: '策略',
            indicators: goodpsy_indicators,
            symbol: 'TX-1',
            interval: 'D',
            panesRatio: 80,
        },
    ],
};
export const goodpsy_initStrategies = () => {
    store.charting.indicatorsPreloaded = [
        ...store.charting.indicatorsPreloaded,
        ...goodpsy_indicators,
    ];
    store.charting.initStrategy({
        configs: [...goodpsy_strategiesGroup.main],
    });
};
