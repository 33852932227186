import { css } from '@emotion/react';
import { memo } from 'react';
import { proxy, useSnapshot } from 'valtio';
import { useThemeStore } from '~/components/theme/useThemeStore';
import useMedia from '~/hooks/useMedia';
import { fill_horizontal_all_center, fill_horizontal_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { UserAvatarAsDialogButton } from '~/modules/auth/components/UserAvatarAsDialogButton';
import { ChartingServerSwitch } from '~/modules/SDK/chart4/components/ChartingServerSwitch';
import { store } from '~/pages/heineken_template/_private/store';
import { FuiUserAvatar } from '../heineken_template/components/FuiUserAvatar';
import { Preset_Topbar } from '../heineken_template/_preset/preset_Topbar';
import { goodpsy_store } from './goodpsy_store';
import { UndevelopedView } from './component/UndevelopedView';
const avatarStore = proxy({ userAvatar: new FuiUserAvatar() });
export const Goodpsy_Topbar = memo(function Daddy960_TopBar(props) {
    const { isPc, isPhone } = useMedia();
    useThemeStore.setState({ theme: 'light' });
    const state = useSnapshot(goodpsy_store).pageState;
    return (<div css={css `
          ${fill_horizontal_cross_center};
          justify-content: end;
          background: linear-gradient(359deg, #ffffff, #ffffff);
          border-bottom: 1px solid #dbdfe7;
        `}>
        {isPc && <UndevelopedView />}
        {isPc && (<div css={css `
              ${fill_horizontal_all_center};
              justify-content: end;
              padding: 0px 16px;
            `}>
            <ChartingServerSwitch charting={store.charting}/>
            <UserAvatarAsDialogButton />
            {/* <avatarStore.userAvatar.AvatarModal
              cssset={css`
                width: 280px;
                height: 360px;
                transition: 0.3s;
              `}
            >
              <OpkevinLogOutView />
            </avatarStore.userAvatar.AvatarModal> */}
          </div>)}

        {!isPc && (<Preset_Topbar showRightBurger={state === 'default' ? true : false} showLeftBurger={props.leftBurger ?? true} componentsInRight={<div css={css `
                  ${fill_horizontal_all_center};
                  justify-content: end;
                `}>
                <ChartingServerSwitch charting={store.charting}/>
                <UserAvatarAsDialogButton />
                {/* <avatarStore.userAvatar.AvatarModal
                  cssset={css`
                    width: 280px;
                    height: 360px;
                  `}
                >
                  <OpkevinLogOutView />
                </avatarStore.userAvatar.AvatarModal> */}
              </div>}/>)}
      </div>);
});
