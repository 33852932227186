import { css } from '@emotion/react';
import SymbolPlatformBase from '../../modules/symbolPlatform/base/SymbolPlatformBase';
import QuoteDetail from '../win988/component/QuoteDetail';
export const goodpsy_initStyling = (templateProps) => {
    templateProps.globalCssset = css `
    #__next {
      ${SymbolPlatformBase.classes.container} {
        &.light {
          box-shadow: 0px 1px 5px 1px #cccccc;
        }
      }

      ${SymbolPlatformBase.classes.header} {
        &.light {
          background-color: #e4e3e3;
        }
      }
      ${QuoteDetail.classes.container} {
        &.light {
          background-color: #ffffff;
        }
      }
    }
  `;
};
