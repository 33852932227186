import styled from '@emotion/styled';
import { Loader } from '@mantine/core';
import { memo } from 'react';
import { useSnapshot } from 'valtio';
import { fill_horizontal_all_center, fill_horizontal_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { store } from '~/pages/heineken_template/_private/store';
import { goodpsy_store } from '../goodpsy_store';
export const PriceBuild = memo(function PriceBuild() {
    const state = useSnapshot(goodpsy_store);
    const valueUp = state.buildDn.toFixed(2);
    const valueDn = state.buildUp.toFixed(2);
    const charting = useSnapshot(store.charting);
    const loadingState = isNaN(state.buildUp) || isNaN(state.buildDn);
    const stockSymbol = charting.symbol.match(/^[\d]{4}$/);
    const enableInterval = charting.interval === '1D' || charting.interval === '60';
    return (<styleds.container>
      {!stockSymbol || !enableInterval ? (<span css={fill_horizontal_all_center}>
          {!stockSymbol ? '僅支援股票商品' : '請使用日線與60分鐘週期'}
        </span>) : (<styleds.text>
          {!loadingState ? (<styleds.infoContent>
              <styleds.title>集結區:</styleds.title>
              <span css={fill_horizontal_all_center}>
                <styleds.value>{valueUp}</styleds.value>
                &nbsp; ~ &nbsp;
                <styleds.value>{valueDn}</styleds.value>
              </span>
            </styleds.infoContent>) : (<Loader variant='dots'/>)}
        </styleds.text>)}
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_horizontal_cross_center};
    padding: 0 16px;
    font-size: 16px;
  `,
    title: styled.div `
    font-weight: 600px;
  `,
    text: styled.div `
    ${fill_horizontal_all_center};
    font-size: 16px;
  `,
    infoContent: styled.div `
    ${fill_horizontal_all_center};
    display: grid;
    grid-template-columns: 20% 80%;
  `,
    value: styled.div `
    padding: 0px 4px;
    background-color: #f3f3f3;
    border-radius: 2px;
    font-weight: 400px;
  `,
};
